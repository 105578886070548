export const upsertHubspotContact = async (data: Object) => {
    const getCookie = (name: string) => {
        const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
        return match ? match[2] : null;
    };

    const hubspotutk = getCookie('hubspotutk');

    if (!hubspotutk) {
        console.warn("No se ha encontrado la cookie hubspotutk, esto podría afectar el seguimiento.");
    }

    let campaignsData = {};
    const urlParams = new URL(window.location.href).searchParams;

    urlParams.forEach((value, key) => {
        campaignsData[key] = value
    });

    const requestData = {
        ...data,
        context: {
            hutk: hubspotutk,
            pageUri: window.location.href,
            pageName: document.title 
        },
        campaignsData: campaignsData
    };

    try {
        const response = await fetch(process.env.REACT_APP_WINAHOST_API_URL + '/service/hubspot/contact/upsert', {
            method: 'POST',
            body: JSON.stringify(requestData),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error(`Error al enviar los datos a HubSpot: ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error("Error al realizar el upsert del contacto:", error);
        throw error;
    }
};
